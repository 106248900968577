const btn = document.getElementById('js-burger-btn');
const btnM = document.getElementById('js-burger-btn-mobile');
const aside = document.getElementById('js-aside');

btn.addEventListener('click', () => {
	aside.classList.toggle('is-aside-open');
});

btnM.addEventListener('click', () => {
	aside.classList.toggle('is-aside-open');
});

document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
     if(bg == "css"){
         e.target.classList.add("is-load");
     }
     else{
      e.target.style.backgroundImage = 'url(' + bg + ')';
     }
 }
});